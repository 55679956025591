<template>
  <div class="contentValidation">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterDepot"
            :options="[{ value: null, text: 'Tous' }, ...computedDepot]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Type</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterType"
            :options="[{ value: null, text: 'tous' }, ...ComputedgetAllType]"
            value-field="value"
            text-field="text"
            @change="handleChangeType"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>

          <SelectComponent
            :options="ComputedgetAllEmployee"
            :value="filterUser"
            label="full_name"
            champName="utilisateur"
            filterName="filterUser"
            :change="handleChange"
            :track-by="'full_name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue"
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Validation</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterValidation"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div v-if="getChargementPaieTh" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="body-box-rapport"
      :class="{ 'resizing-body-box-rappot': computeAdmin }"
    >
      <div class="table-rapport-style w-100-p">
        <b-table
          responsive
          show-empty
          id="my-table"
          class="custom-table-style tableValidation  table-header "
          :items="computedgetuserSalary"
          :fields="computedFiled"
          bordered
          sticky-header
          hover
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(details)="data">
            <button class="btn" @click="data.toggleDetails">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(statue)="data">
            <div
              v-if="
                checkPermission($options.name) ||
                  computeReponsableDepot ||
                  computeReponsableCommercial
              "
            >
              <b-button
                size="sm"
                class="iconValide"
                :class="{
                  activeValide: data.item.statue == 'valid'
                }"
                title="salaire valide"
                @click.prevent.stop="
                  handelValidationSalary({
                    user: data.item,
                    validation: 'valid'
                  })
                "
              >
                <font-awesome-icon icon="check" />
              </b-button>
              <b-button
                size="sm"
                class="iconInvalide"
                :class="{
                  activeInvalid: data.item.statue == 'refuse'
                }"
                title="salaire invalide"
                @click.prevent.stop="
                  handelValidationSalary({
                    user: data.item,
                    validation: 'refuse'
                  })
                "
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </div>
          </template>

          <template v-slot:cell(name)="data">
            {{ data.item.employee }}
          </template>
          <template v-slot:cell(comment)="data">
            <commentInput
              :item="data.item"
              :value="data.item.comment"
              :updateFunction="newCommentTh"
            />
          </template>
          <template v-slot:cell(type)="data">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(month)="data">
            {{ data.item.month }}
          </template>
          <template v-slot:cell(acompt)="data">
            {{ data.item.acompt }}
          </template>

          <template v-slot:cell(IGD)="data">
            {{ data.item.IGD }}
          </template>
          <template v-slot:cell(additional_prime)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="additional_prime"
                :item="data.item"
                :year="filteryear"
                :value="data.item.additional_prime"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.additional_prime }}</template>
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }}
          </template>
          <template v-slot:cell(previsite)="data">
            {{ data.item.previsite }}
          </template>
          <template v-slot:cell(vente)="data">
            {{ data.item.vente }}
          </template>
          <template v-slot:cell(interne)="data">
            {{ data.item.interne }}
          </template>
          <template v-slot:cell(externe)="data">
            {{ data.item.externe }}
          </template>
          <template v-slot:cell(variable_vmc)="data">
            {{ data.item.vmc }}
          </template>
          <template v-slot:cell(variable_pac)="data">
            {{ data.item.pac }}
          </template>
          <template v-slot:cell(fix)="data">
            {{ data.item.fix }}
          </template>
          <template v-slot:cell(prime_qualite)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="prime_qualite"
                :item="data.item"
                :year="filteryear"
                :value="data.item.prime_qualite"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.prime_qualite }}</template>
          </template>
          <template v-slot:cell(prime_objectif)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="prime_objectif"
                :item="data.item"
                :year="filteryear"
                :value="data.item.prime_objectif"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.prime_objectif }}</template>
          </template>
          <template v-slot:cell(prime_sav)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="prime_sav"
                :item="data.item"
                :year="filteryear"
                :value="data.item.prime_sav"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
            /></template>
            <template v-else>{{ data.item.prime_sav }}</template>
          </template>
          <template v-slot:cell(other_prime_name)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="other_prime_name"
                :item="data.item"
                :year="filteryear"
                type="text"
                :value="data.item.other_prime_name"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.other_prime_name }}</template>
          </template>
          <template v-slot:cell(other_prime_value)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="other_prime_value"
                :item="data.item"
                :year="filteryear"
                :value="data.item.other_prime_value"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
            /></template>

            <template v-else>{{ data.item.other_prime_value }}</template>
          </template>
          <template v-slot:cell(deducation)="data">
            {{ data.item.deducation }}
          </template>

          <template v-slot:cell(download_interne)="data">
            <b-button
              v-if="
                computedCantExport ||
                  computeReponsableDepot ||
                  computeReponsableCommercial
              "
              size="sm"
              class="iconDownload"
              title="télecharger rapport salaire"
              @click.prevent.stop="
                download({ data: data.item, type: 'interne' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(download)="data">
            <b-button
              v-if="
                computedCantExport ||
                  computeReponsableDepot ||
                  computeReponsableCommercial
              "
              size="sm"
              class="iconDownload"
              title="télecharger rapport salaire"
              @click.prevent.stop="download({ data: data.item, type: null })"
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template #row-details="data">
            <template v-if="data.item.depots">
              <div
                class="body-task mb-2"
                v-for="(depotDetails, index) in data.item.depots"
                :key="'depot' + index"
              >
                <div class="align-td-item">
                  <td class="col-plus item-details-style mr-3 ">
                    <font-awesome-icon
                      class="caret-down ml-3 mr-5 "
                      :icon="
                        depotDetails.show == false
                          ? 'caret-right'
                          : 'caret-down'
                      "
                      @click="depotDetails.show = !depotDetails.show"
                    />
                  </td>
                  <td
                    class="item-details-style justify-content-sb item-details-style-custom"
                  >
                    <div>
                      <b class="mr-2">Numéro de dépôt </b>
                      {{ depotDetails.name }}
                    </div>
                    <font-awesome-icon
                      icon="arrow-alt-circle-down"
                      class="icon"
                      @click="
                        download({
                          data: data.item,
                          type: null,
                          depot: depotDetails.name
                        })
                      "
                    />
                  </td>

                  <td class="item-details-style justify-content-sb">
                    <div>
                      <b class="mr-2">Total </b>{{ depotDetails.total }} €
                    </div>
                  </td>
                  <td class="item-details-style justify-content-sb">
                    <div>
                      <b class="mr-2"> Acompte </b>
                      {{ depotDetails.acompt }} €
                    </div>
                  </td>
                  <template v-if="data.item.type == 'Commercial sedentaire'">
                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Non vente telepro : </b>
                        {{ depotDetails.interne }} €
                      </div>
                      <div>{{ depotDetails.interne }}</div>
                    </td>
                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Vente telepro</b
                        >{{ depotDetails.externe }} €
                      </div>
                    </td>
                  </template>
                  <template
                    v-if="
                      data.item.type == 'Pre visiteur' ||
                        data.item.type == 'Commercial terrain'
                    "
                  >
                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Vente</b> {{ depotDetails.vente }} €
                      </div>
                    </td>

                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Previsite</b>
                        {{ depotDetails.previsite }} €
                      </div>
                    </td>
                  </template>
                </div>

                <template v-if="depotDetails.show && depotDetails.show == true">
                  <div class="details-num-dossier">
                    <div class="align-td-item">
                      <td class="item-details-style num-dossier">
                        <b>Numéro dossier</b>
                      </td>

                      <td class="item-details-style "><b>Nom </b></td>

                      <td class="item-details-style "><b>Prénom</b></td>
                      <td class="item-details-style "><b>Montant min</b></td>
                      <td class="item-details-style "><b>Acompte</b></td>
                    </div>
                    <div
                      class="align-td-item"
                      v-for="(task, indextask) in depotDetails.tasks"
                      :key="'depot' + depotDetails.name + 'tasks' + indextask"
                    >
                      <td class="item-details-style ">
                        {{ task.numero_dossier }}
                      </td>

                      <td class="item-details-style ">{{ task.nom }}</td>
                      <td class="item-details-style ">{{ task.prenom }}</td>

                      <td class="item-details-style ">
                        {{ task.montant_final }} €
                      </td>
                      <td class="item-details-style ">{{ task.acompt }} €</td>
                    </div>
                  </div>

                  <div
                    class="border-botom"
                    v-if="
                      data.item.depots && index < data.item.depots.length - 1
                    "
                  ></div>
                </template>

                <div
                  class="border-botom"
                  v-if="data.item.depots && index < data.item.depots.length - 1"
                ></div>
              </div>
            </template>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowUserSalaryTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditableInput from '../../component/EditableInput.vue';
import moment from 'moment';
export default {
  name: 'Validation-de-paie-commerciaux-Th',
  data() {
    return {
      filterUser: { full_name: 'tous', id: null },
      filterType: null,
      filteryear: null,
      filterDepot: null,
      filterValidation: null,
      filterMonth: null,
      ListYear: [],
      page: 1,
      perPage: 10,
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En attend' },
        { value: 'valid', text: 'Validé' },
        { value: 'refuse', text: 'Non validé' }
      ],
      fieldsPrévisiteur: [
        //prévisiteur && Commercial terrain

        { key: 'previsite', label: 'Prévisite' },
        { key: 'vente', label: 'Vente' },
        {
          key: 'total',
          label: 'Total en €'
        }
      ],
      fieldsSedentaire: [
        //Sédentaire && Confirmateur
        { key: 'externe', label: 'Vente telepro' },
        { key: 'interne', label: 'Non vente telepro' },
        {
          key: 'total',
          label: 'Total en €'
        }
      ],
      fieldsSticky: [
        { key: 'details', label: '', tdClass: 'color', thClass: 'th-color' },
        {
          key: 'name',
          label: 'Nom',
          thClass: 'th-name-fixed',
          tdClass: 'td-name-fixed'
        },
        { key: 'type', label: 'Type' },
        { key: 'month', label: 'Mois' },
        { key: 'acompt', label: 'Acompt' }
      ],
      fields: [
        // { key: 'variable_vmc', label: 'Variable Vmc' },
        // { key: 'variable_pac', label: 'Variable PAC' },
        // {
        //   key: 'IGD',
        //   label: 'IGD ',
        //   headerTitle: 'Indemnité Grand deplacement '
        // },
        // { key: 'prime_qualite', label: 'Prime Qualité' },
        // { key: 'prime_objectif', label: 'Prime Objectif' },
        // { key: 'prime_sav', label: 'Prime Sav' },
        // { key: 'additional_prime', label: 'Prime exceptionnelle' },
        // { key: 'other_prime_name', label: 'Autre Prime (Nom)' },
        // { key: 'other_prime_value', label: 'Autre Prime (Montant)' },
        { key: 'other_prime_value', label: 'Autres' },
        { key: 'deducation', label: 'Déducation' },
        { key: 'comment', label: 'Commentaire' },

        {
          key: 'download',
          label: 'Télécharger',
          thClass: 'thtotal',
          tdClass: 'tdtotal'
        },
        {
          key: 'statue',
          label: 'Validation',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation'
        }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getSalaryFiltredTh',
      'newCommentTh',
      'validationSalaryTh',
      'downloadSalaryValidationPDFTh',
      'getOnlineTypeTh',
      'getOnlineSalaryEmployeeBytypeTh',
      'updatePrimeTh',
      'featchDepotForYearTH',
      'fetchMonthForYearTH'
    ]),
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.statue == 'refuse') return 'ligneRefuse';
      else if (item.statue == 'valid') return 'ligneAccepted';
      else return 'ligneEnAttend';
    },
    setLocalStoragr() {
      localStorage.setItem(
        'Validation-salaire-th-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterDepot: this.filterDepot,
          filterUser: this.filterUser,
          filterValidation: this.filterValidation,
          filterType: this.filterType
        })
      );
    },
    hendleGetTypeAndUser() {
      this.getOnlineTypeTh({
        year: this.filteryear,
        salarie: 'oui',
        month: this.filterMonth
      });
      this.getOnlineSalaryEmployeeBytypeTh({
        year: this.filteryear,
        type: this.filterType,
        salarie: 'oui',
        month: this.filterMonth
      });
    },
    filterTable() {
      this.getSalaryFiltredTh({
        depot: this.filterDepot,
        employee_id: this.filterUser,
        year: this.filteryear,
        page: this.page,
        validation: this.filterValidation,
        type: this.filterType,
        perPage: this.perPage,
        month: this.filterMonth
      });
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterType = null;
      this.filterUser = { full_name: 'tous', id: null };
      this.filterTable();
      this.hendleGetTypeAndUser();
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      sessionStorage.setItem('page-validation-salaire-th', this.page);
      this.setLocalStoragr();
      this.hendleGetTypeAndUser();
      this.filterTable();
    },
    handleChangeDepot() {
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.hendleGetTypeAndUser();
      this.filterTable();
    },
    handleChangeYear() {
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.setLocalStoragr();
      this.page = 1;
      sessionStorage.setItem('page-validation-salaire-th', this.page);
      this.fetchMonthForYearTH(this.filteryear);
      this.featchDepotForYearTH(this.filteryear);
      this.hendleGetTypeAndUser();

      this.filterTable();
    },
    handleChangeType() {
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      sessionStorage.setItem('page-validation-salaire-th', this.page);
      this.setLocalStoragr();
      this.getOnlineSalaryEmployeeBytypeTh({
        year: this.filteryear,
        depot: this.filterDepot,
        type: this.filterType,
        salarie: 'oui',
        month: this.filterMonth
      });
      this.filterTable();
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-validation-salaire-th', this.page);
      this.filterTable();
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-validation-salaire-th', this.page);
      this.filterTable();
    },
    handelValidationSalary(data) {
      this.validationSalaryTh({
        item: data.user,
        column: 'statue',
        value: data.validation
      });
    },
    download(item) {
      this.downloadSalaryValidationPDFTh({
        item: item.data,
        year: this.filteryear,
        type: item.type,
        depot: item.depot ? item.depot : null
      });
    }
  },
  computed: {
    ...mapGetters([
      'getAllEmployee',
      'getChargementPaieTh',
      'getTotalRowUserSalaryTh',
      'getUserSalaryTh',
      'getOnlineTypeEmloyeesTh',
      'getActiveEmployyesTh',
      'getUserData',
      'checkPermission',
      'checkPermission',
      'getMonthForYearTh',
      'getAllDepotForYearTh'
    ]),
    computeAdmin: function() {
      return this.getUserData && this.getUserData.role != 'admin';
    },
    computedDepot() {
      let tab = [];
      if (this.filterMonth == null) {
        tab = this.getAllDepotForYearTh.map(item => ({
          value: item.depot,
          text: item.depot
        }));
        return tab;
      } else {
        let table = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        if (table.length) {
          tab = table[0].depots.map(item => ({
            value: item.depot,
            text: item.depot
          }));
          return tab;
        } else {
          return [];
        }
      }
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedCantExport() {
      return this.checkPermission(this.$options.name);
    },
    ComputedgetAllType() {
      return this.getOnlineTypeEmloyeesTh.map(item => {
        return {
          value: item,
          text: item
        };
      });
    },
    ComputedgetAllEmployee() {
      return [{ id: null, full_name: 'tous' }, ...this.getActiveEmployyesTh];
    },
    computedgetuserSalary() {
      if (this.filterValidation != null) {
        return this.getUserSalaryTh.filter(
          item => item.statue == this.filterValidation
        );
      }
      return this.getUserSalaryTh;
    },
    computeReponsableDepot: function() {
      return this.getUserData && this.getUserData.role === 'responsable_depot';
    },
    computedTypeValidation: function() {
      return function(data) {
        if (
          data.type == 'Responsable planning' ||
          data.type == 'Admin GE' ||
          data.statue == 'valid'
        ) {
          return false;
        }
        return true;
      };
    },
    computedFiled() {
      if (this.filterType == null) {
        let fieldsNew = this.fieldsSedentaire.filter(
          item => item.key != 'total'
        );
        return [
          ...this.fieldsSticky,
          ...fieldsNew,
          ...this.fieldsPrévisiteur,

          ...this.fields
        ];
      }
      if (
        this.filterType == 'Pre visiteur' ||
        this.filterType == 'Commercial terrain'
      ) {
        // let fieldsNews = this.fields.filter(item => item.key != 'download');
        return [
          ...this.fieldsSticky,
          ...this.fieldsPrévisiteur,
          ...this.fields
        ];
      }
      if (this.filterType == 'Commercial sedentaire') {
        // let fieldsNews = this.fields.filter(item => item.key != 'download');
        return [...this.fieldsSticky, ...this.fieldsSedentaire, ...this.fields];
      }
      let fieldsNew = this.fieldsSedentaire.filter(item => item.key != 'total');
      return [
        ...this.fieldsSticky,
        ...fieldsNew,
        ...this.fieldsPrévisiteur,
        ...this.fields
      ];
    },
    computeReponsableCommercial: function() {
      return (
        this.getUserData && this.getUserData.role === 'responsable_commercial'
      );
    }
  },
  components: {
    commentInput: () => import('../../component/CommentInput.vue'),
    SelectComponent: () => import('../../SelectComponent.vue'),
    EditableInput
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('Validation-salaire-th-Filters')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('Validation-salaire-th-Filters')
      ).filteryear;
      this.filterDepot = JSON.parse(
        localStorage.getItem('Validation-salaire-th-Filters')
      ).filterDepot;
      this.filterUser = JSON.parse(
        localStorage.getItem('Validation-salaire-th-Filters')
      ).filterUser;
      this.filterValidation = JSON.parse(
        localStorage.getItem('Validation-salaire-th-Filters')
      ).filterValidation;
      this.filterType = JSON.parse(
        localStorage.getItem('Validation-salaire-th-Filters')
      ).filterType;
    } else {
      this.filteryear = courantYear;
      this.setLocalStoragr();
    }
    if (sessionStorage.getItem('page-validation-salaire-th')) {
      this.page = sessionStorage.getItem('page-validation-salaire-th');
    } else {
      sessionStorage.setItem('page-validation-salaire-th', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.fetchMonthForYearTH(this.filteryear);
    this.featchDepotForYearTH(this.filteryear);
    this.hendleGetTypeAndUser();

    this.filterTable();
  }
};
</script>

<style scoped lang="scss">
.tableValidation {
  height: calc(100vh - 175px);
  max-height: calc(100vh - 175px);
  margin-bottom: 0px;
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .caret-down {
    font-size: 16px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .body-task {
    // display: flex;
    margin-left: 26px;
    width: 1156px;
    position: sticky;
    left: 5px;
    width: 1200px;
    span {
      text-align: start;
      min-width: 172px;
    }
    .span_num {
      width: 20%;
    }
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .details-num-dossier {
    border: 1px solid #f6f5fb;
    width: 782px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 3%;
    background-color: #f0f1ff;
    .num-dossier {
      border-bottom: 1px solid #f6f5fb;
      border-right: 1px solid #f6f5fb;
    }
  }
  .item-details-style {
    width: 15%;
  }
  .item-details-style-custom {
    width: 30%;
  }
}
.body-box-rapport {
  height: calc(100vh - 106px);
}
.resizing-body-box-rappot {
  height: calc(100vh - 106px);
  .tableValidation {
    max-height: calc(100vh - 180px) !important;
    height: calc(100vh - 180px) !important;
  }
}
.sub-menu .body .body-box-rapport {
  height: calc(100vh - 175px);
}
.List {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.iconDownload {
  padding: 1px 4px;
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
  &:hover {
    background-color: #6c757d;
    border-color: white;
    color: white;
  }
}

.contentValidation {
  margin: 10px;
  width: 100%;
  display: inline;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
</style>
